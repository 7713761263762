html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --bg-light-grey: #f7f8fc;
  --bg-dark-grey: #47474a;
  --black: #313133;
  --panasonic-blue: #0041c0;
  --accent-light-blue: #00AEEF;
  --success: #12c600;
  --accent-light-green: #C2C200;
  --warning: #ffb700;
  --error: #ff1f44;
  --border-light: #D9DADC;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
